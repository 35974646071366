.step {
	border-radius: 6px;
	margin-bottom: 12px;
	background-color: var(--eds-color-white);
	box-shadow:
		0 1px 2px 0 rgb(29 30 38 / 5%),
		0 3px 10px 0 rgb(29 30 38 / 10%);

	&:last-child {
		margin-bottom: 0;
	}
}
