.step-header {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 18px 36px;

	&::after {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		background: #d9dbe3;
		content: '';
	}

	&__pencil {
		&-prepared-container {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&-icon-container {
			width: 36px;
			height: 36px;
			cursor: pointer;
		}
	}

	&__step-idx {
		margin: 0;
		color: #d1cfcf;
		font-size: 16px;
		font-weight: 600;
	}

	&__title {
		display: flex;
		align-items: center;

		&-prepend-container {
			display: flex;
			width: 36px;
			height: 36px;
			box-sizing: border-box;
			align-items: center;
			justify-content: center;
			border: 1px solid #d1cfcf;
			border-radius: 36px;
			margin-right: 24px;
		}
	}
}
