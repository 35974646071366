.container {
	height: 100%;

	&-content {
		position: fixed;
		z-index: 9999;
		background-attachment: fixed;
		background-image: linear-gradient(rgb(2 2 2 / 55%), rgb(2 2 2 / 55%)),
			url('./../../assets/imgs/login-background.jpg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		inset: 0;

		.login {
			display: flex;
			height: 100%;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.action {
				line-height: 4rem;

				button {
					border-color: var(--eds-color-white);
					background-color: var(--eds-color-white);
					color: var(--eds-color-black);

					&:hover {
						border-color: #b2b2b2;
						background-color: #b2b2b2;
					}
				}
			}
		}

		.footer {
			position: absolute;
			bottom: 15px;
			width: 100%;
			text-align: center;
		}
	}
}
