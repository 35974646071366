.h200 {
	height: 200px;
}

.h300 {
	height: 300px;
}

.h400 {
	height: 400px;
}

.w100 {
	width: 100px;
}

.w200 {
	width: 200px;
}

.w300 {
	width: 300px;
}

.w400 {
	width: 400px;
}

.full-height {
	height: 100%;
}

.full-width {
	width: 100%;
}

.mw600 {
	max-width: 600px;
}

.mw700 {
	max-width: 700px;
}
