.container {
	overflow: hidden;
	margin: 0 auto;

	&-img {
		max-width: calc(100vh - 20px);
		max-height: calc(100vh - 120px);
		background-color: transparent;
		background-repeat: no-repeat;
		background-size: cover;
		object-fit: contain;
	}
}
