$half-of-screen: 50%;
$leftsidebar-width: 250px;

@mixin full-screen {
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	@content;
}

.container {
	background-color: var(--eds-color-background-secondary);

	&-full-screen {
		@include full-screen;
	}

	&-full-screen--transparent {
		@include full-screen;

		opacity: 0.4;
	}

	&-in-container {
		position: absolute;
		z-index: 9999;
		width: calc(100% - $leftsidebar-width);
		height: 100%;
		opacity: 0.4;
	}
}

.spinner {
	position: absolute;
	top: $half-of-screen;
	left: $half-of-screen;
	transform: translate(-$half-of-screen, -$half-of-screen);
}
