.card {
	position: relative;
	overflow: hidden;
	width: 100%;

	&:hover {
		cursor: pointer;
	}

	&--no-image {
		position: absolute;
		inset: 0;
	}

	&-body {
		&-img {
			position: absolute;
			width: 100%;
			background-color: lightgrey;
			background-repeat: no-repeat;
			background-size: cover;
			inset: 0;
		}

		&-info {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 64px;
			padding: 8px 16px;
			backdrop-filter: blur(20.3871px);
			fill: rgb(17 17 17 / 10%);

			& > p {
				overflow: hidden;
				min-width: 180px;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}
