$vertical-bar-width: 250px;

.container {
	width: calc(100vw - $vertical-bar-width);
	margin-left: $vertical-bar-width;

	&-content {
		display: flex;
		height: calc(100vh);
		flex-direction: column;
		overflow-x: hidden;
		overflow-y: auto;
	}
}

.vertical-nav {
	position: absolute;
}
