.uppercase {
	text-transform: uppercase;
}

.lowercase {
	text-transform: lowercase;
}

.capitalize {
	text-transform: capitalize;
}

.wordbreak--initial {
	word-break: initial;
}

.line-height--1 {
	line-height: 1;
}

.line-height--1-5 {
	line-height: 1.5;
}

.line-height--2 {
	line-height: 2;
}

.line-height--3 {
	line-height: 3;
}
