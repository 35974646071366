.container {
	position: relative;
	background: var(--eds-color-white);

	&::before {
		position: absolute;
		bottom: 6px;
		width: 100%;
		height: 0;
		border-bottom: 2px dotted var(--eds-color-grey-2);
		content: '';
		line-height: 0;
	}

	& > span > label {
		position: relative;
		z-index: 1;
		display: inline;
		padding-right: 4px;
		background: var(--eds-color-white);
	}

	& > span,
	& > span > span {
		z-index: 2;
		background: var(--eds-color-white);
		text-align: right;
	}
}
