.container {
	position: fixed;
	display: flex;
	width: 250px;
	height: 100vh;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 0 16px 16px;
	background-color: var(--eds-color-black);
	color: var(--eds-color-text-default);
	overflow-y: auto;
	overscroll-behavior: contain;
}

.logo {
	position: relative;

	&-sub-title {
		position: absolute;
		top: 65%;
		left: 1%;
		width: 215px;

		& > p {
			text-transform: uppercase;
		}
	}
}
