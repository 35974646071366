.container {
	position: relative;

	label[class='eds-label eds-color--text-secondary eds-type--body-3'],
	label[class='eds-label eds-color--text-secondary eds-type--body-3 description-tooltip'] {
		display: block;
		overflow: hidden;
		width: 450px;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&-description-tooltip {
		position: absolute;
		z-index: 1;
		display: block;
		max-width: 550px;
		height: fit-content;
		padding: 2px 5px;
		border: 1px solid #ccc;
		border-radius: 5px;
		background: #fff;
		white-space: wrap;
	}
}
