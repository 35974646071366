*,
*::before,
*::after {
	box-sizing: border-box;
}

* {
	padding: 0;
	margin: 0;
	font: inherit;
}

body {
	background: #efefef;
}
