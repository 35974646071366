.container {
	display: flex;
	align-items: center;

	& > img {
		width: 65px;
		margin-right: 10px;
		filter: contrast(0) brightness(2);
		filter: 'contrast(0) brightness(2)';
	}

	& > p:last-child {
		display: inline-block;
		max-width: min-content;
		font-size: 20px;
		font-weight: 800;
		line-height: initial;
		text-transform: uppercase;
	}
}
