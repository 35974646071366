.skeleton {
	animation: skeleton-loading 1s linear infinite alternate-reverse;
	opacity: 0.7;
}

.skeleton-text {
	height: 20px;
	border-radius: 0.125rem;
	margin-bottom: 12px;
}

@keyframes skeleton-loading {
	0% {
		background-color: hsl(200deg 20% 60%);
	}

	100% {
		background-color: hsl(200deg 20% 70%);
	}
}
