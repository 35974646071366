.container {
	width: 80vw;
	max-height: 90vh;

	& > pre {
		width: 100%;
		color: red;
		overflow-x: auto;
		white-space: pre-wrap;
	}
}
