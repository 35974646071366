.container {
	width: 100%;
	min-height: 100vh;
	background-color: black;

	&-overlay {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-end;
		padding: 0 0 44px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;

		&::before {
			position: absolute;
			content: '';
			inset: 0;
			transition: all 0.3s ease 0s;
		}
	}
}
